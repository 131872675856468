import { ENV } from '@common/env';
import { Socket, io } from 'socket.io-client';
import { WebsocketsConnectionNotEstablished } from '../errors/websockets.errors';
import { AlertsServiceSymbol } from '@infrastructure/alerts/alerts.module';
import { AlertsService } from '@infrastructure/alerts/services/alerts.service';
import {
	WebsocketsConnectionErrorAlert,
	WebsocketsConnectionRestoredAlert,
} from '../alerts/websockets.alerts';
import { LoggerSymbol } from '@infrastructure/logger/logger.module';
import { ILogger } from '@infrastructure/logger/types/logger.types';

export class WebsocketsService {
	private readonly alertsService: AlertsService;
	private readonly logger: ILogger;
	private socket: Socket | null;

	constructor(dependencies: { [AlertsServiceSymbol]: AlertsService; [LoggerSymbol]: ILogger }) {
		this.socket = null;
		this.alertsService = dependencies[AlertsServiceSymbol];
		this.logger = dependencies[LoggerSymbol];
	}

	connect(authToken: string) {
		if (!this.socket) {
			this.socket = io(ENV.WEBSOCKET_URL, {
				extraHeaders: { authorization: `Bearer ${authToken}` },
				withCredentials: true,
				transports: ['websocket'],
			}).connect();

			this.socket.on('connect_error', this.onConnectError.bind(this));
		}
	}

	emit(eventName: string, data: unknown, callback: Function = () => {}) {
		if (!this.socket) {
			this.logger.error(new WebsocketsConnectionNotEstablished());
			return;
		}

		this.socket.emit(eventName, data, callback);
	}

	hasListener(eventName: string, context: string, callback: Function) {
		if (!this.socket) {
			this.logger.error(new WebsocketsConnectionNotEstablished());
			return;
		}

		return this.socket
			.listeners(eventName)
			.map((fn) => fn.name)
			.includes(callback.name);
	}

	on(eventName: string, context: string, callback: (...args: any[]) => void) {
		if (!this.socket) {
			this.logger.error(new WebsocketsConnectionNotEstablished());
			return;
		}

		// Safety mechanism that prevents registering multiple listeners
		// from the same context (especially when using hot module reloading on dev).
		// Function name is edited to include context as prefix.
		Object.defineProperty(callback, 'name', {
			value: this.addContextToCallbackName(context, callback),
			writable: false,
		});
		if (this.hasListener(eventName, context, callback)) return;

		this.socket.on(eventName, callback);

		return callback;
	}

	off(eventName: string, context: string, callback: (...args: any[]) => void) {
		if (!this.socket) {
			this.logger.error(new WebsocketsConnectionNotEstablished());
			return;
		}

		// The function simply returns if listener does not exist
		this.socket.removeListener(eventName, callback);
	}

	addContextToCallbackName(context: string, callback: Function) {
		return `${context}/${callback.name}`;
	}

	onConnectError() {
		this.alertsService.addUniqueAlert(WebsocketsConnectionErrorAlert);

		if (this.socket && this.socket.listeners('connect').length === 0) {
			this.socket?.on('connect', this.onReconnect.bind(this));
		}
	}

	onReconnect() {
		this.alertsService.removeUniqueAlert(WebsocketsConnectionErrorAlert.id);

		this.alertsService.addAlerts([WebsocketsConnectionRestoredAlert]);

		this.socket?.removeListener('connect', this.onReconnect);
	}
}
