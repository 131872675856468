export enum OrganizationType {
	PERSONAL = 'personal',
	SHARED = 'shared',
}

export interface Organization {
	id: number;

	name: string;

	slug: string;

	avatarUrl: string;

	type: OrganizationType;

	canManage?: { result: boolean; reason: string };

	billingAccountId: string;
}

export interface CreateOrganizationRequest {
	name: string;

	slug: string;

	invitees: string[];
}

export interface UpdateOrganizationRequest {
	name?: string;

	billingAccountId?: string;

	avatarUrl?: string;
}

export interface SlugValidationResponse {
	valid: boolean;

	suggestions: string[];
}

export interface SubscriptionStatusResponse {
	status: boolean;
}
