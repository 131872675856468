import { IntegrationsApiAdapterSymbol } from '@modules/integrations/integrations.module';
import { IntegrationsApiAdapter } from '../api/integrations.api';
import { Integration, Integrations } from '@modules/integrations/types/integrations.types';
import { Organization } from '@modules/organizations/types/organizations.types';

export class IntegrationsService {
	private readonly integrationsApiAdapter;

	constructor(dependencies: { [IntegrationsApiAdapterSymbol]: IntegrationsApiAdapter }) {
		this.integrationsApiAdapter = dependencies[IntegrationsApiAdapterSymbol];
	}

	async getIntegrations(orgId: Organization['id']): Promise<Integrations> {
		return this.integrationsApiAdapter.get(orgId);
	}

	async updateIntegration(
		integrationId: Integration['id'],
		organizationId: Organization['id'],
		payload: Integration,
	) {
		await this.integrationsApiAdapter.updateIntegration(integrationId, organizationId, payload);
	}

	async googleCalendarAuthorize(code: string, state: string): Promise<void> {
		await this.integrationsApiAdapter.googleCalendarAuthorize(code, state);
	}

	async disconnectGoogleCalendar(organizationId: Organization['id']) {
		return this.integrationsApiAdapter.disconnectGoogleCalendar(organizationId);
	}
}
