import { asClass } from 'awilix';
import { useDependenciesContainer } from '@common/dependencies/useDependenciesContainer';

import { IntegrationsApiAdapter } from '@modules/integrations/api/integrations.api';
import { IntegrationsService } from '@modules/integrations/services/integrations.service';

export const IntegrationsApiAdapterSymbol: unique symbol = Symbol('Integrations API Adapter');
export const IntegrationsServiceSymbol: unique symbol = Symbol('Integrations Service');

export const integrationsModule = {
	[IntegrationsApiAdapterSymbol]: asClass(IntegrationsApiAdapter),
	[IntegrationsServiceSymbol]: asClass(IntegrationsService),
};

export function useIntegrationsModule() {
	const container = useDependenciesContainer();
	return {
		integrationsService: container.resolve(IntegrationsServiceSymbol) as IntegrationsService,
	};
}
