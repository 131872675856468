import ky, { HTTPError, type KyResponse, type Options } from 'ky';
import {
	EVENT_UNAUTHENTICATED_REQUEST,
	EVENT_UNAUTHORIZED_REQUEST_SUBSCRIPTION_EXPIRED,
} from '@common/events';
import { useAuthStore } from '@modules/auth/stores/auth.store';
import { IDeserializer } from '@infrastructure/json/deserializers/deserializer.interface';
import { DeserializerSymbol } from '@infrastructure/json/json.module';
import { CustomHttpError } from '../errors/http.errors';

export const QUERY_PARAMS_SEPARATOR = ',';

export interface HttpServiceOptions extends Options {}

export interface IHttpService {
	get(url: string, options?: HttpServiceOptions): Promise<KyResponse>;
	post(url: string, options?: HttpServiceOptions): Promise<KyResponse>;
	put(url: string, options?: HttpServiceOptions): Promise<KyResponse>;
	patch(url: string, options?: HttpServiceOptions): Promise<KyResponse>;
	delete(url: string, options?: HttpServiceOptions): Promise<KyResponse>;
	json<T>(request: Promise<KyResponse>): Promise<T>;
}

export class HttpService implements IHttpService {
	private options: HttpServiceOptions = {};
	private deserializer;
	private authStore;
	private http;

	constructor(args: { options?: HttpServiceOptions; [DeserializerSymbol]: IDeserializer }) {
		if (args.options) {
			this.options = args.options;
		}

		this.deserializer = args[DeserializerSymbol];

		// TODO: Remove this dependency!
		this.authStore = useAuthStore();

		this.http = ky.create({
			hooks: {
				beforeRequest: [
					(request) => {
						request.headers.set('Authorization', `Bearer ${this.authStore.getAccessToken()}`);
					},
				],
			},
		});
	}

	public async json<T>(request: Promise<KyResponse>): Promise<T> {
		const response = await request;
		const json: { data: Object | Array<Object> } = await response.json();

		return this.deserializer.deserialize<T>(json);
	}

	public async get(url: string, options?: HttpServiceOptions): Promise<KyResponse> {
		try {
			return await this.http.get(url, { ...this.options, ...options });
		} catch (err) {
			return this.handleError(err);
		}
	}
	public async post(url: string, options?: HttpServiceOptions): Promise<KyResponse> {
		try {
			return await this.http.post(url, { ...this.options, ...options });
		} catch (err) {
			return this.handleError(err);
		}
	}
	public async put(url: string, options?: HttpServiceOptions): Promise<KyResponse> {
		try {
			return await this.http.put(url, { ...this.options, ...options });
		} catch (err) {
			return this.handleError(err);
		}
	}
	public async patch(url: string, options?: HttpServiceOptions): Promise<KyResponse> {
		try {
			return await this.http.patch(url, { ...this.options, ...options });
		} catch (err) {
			return this.handleError(err);
		}
	}
	public async delete(url: string, options?: HttpServiceOptions): Promise<KyResponse> {
		try {
			return await this.http.delete(url, { ...this.options, ...options });
		} catch (err) {
			return this.handleError(err);
		}
	}

	private async handleError(err: unknown) {
		if (err instanceof HTTPError) {
			const response = err.response;

			if (response.status === 401) {
				const unauthenticatedRequestEvent = new CustomEvent(EVENT_UNAUTHENTICATED_REQUEST);
				window.dispatchEvent(unauthenticatedRequestEvent);
				return response;
			}

			if (response.status === 403) {
				const data = await response.json();
				if (data && data.message === 'subscription_inactive') {
					const unauthorizedRequestEvent = new CustomEvent(
						EVENT_UNAUTHORIZED_REQUEST_SUBSCRIPTION_EXPIRED,
					);
					window.dispatchEvent(unauthorizedRequestEvent);
				}

				return response;
			}

			throw new CustomHttpError(err.response, err.request, err.options);
		}

		throw err;
	}

	private setHeader(key: string, value: string): void {
		if (!Object.hasOwn(this.options, 'headers')) {
			this.options.headers = {};
		}

		this.options.headers = {
			...this.options.headers,
			...{ key, value },
		};
	}
}
