// Polyfills
import 'whatwg-fetch';

// Main app
import { ENV } from '@common/env';
import { type App, type Component, createApp } from 'vue';
import { type Router } from 'vue-router';

// Plugins
import { useI18n } from '@plugins/i18n.plugin';
import { usePinia } from '@plugins/pinia.plugin';
import { useBugsnag } from '@plugins/bugsnag.plugin';
import { useFormkit } from '@plugins/formkit.plugin';
import { useFontawesome } from '@plugins/fontawesome.plugin';
import { useUnhead } from '@plugins/unhead.plugin';
import VueSmoothScroll from 'vue3-smooth-scroll';

import { autoAnimatePlugin } from '@formkit/auto-animate/vue';

// Dependencies
import {
	DependenciesContainer,
	DependenciesContainerIdentifier,
} from '@common/dependencies/dependencies.container';
import { dependenciesRegistry } from '@common/dependencies/dependencies.registry';

// Shared styles
import '../index.css';

// Global components (also add them to src/components.d.ts)
import IconFontAwesome from '@ui/components/icons/IconFontAwesome.vue';
import { useFloatingVue } from '@plugins/floating-vue.plugin';

interface ISetupAppProps {
	root: Component;
	router: Router;
}

export const setupApp = (props: ISetupAppProps): App => {
	const app = createApp(props.root);

	// Register dependencies
	const container = new DependenciesContainer().register(dependenciesRegistry);
	app.provide(DependenciesContainerIdentifier, container);

	// Setup universal plugins
	useI18n(app);
	usePinia(app);
	useFormkit(app);
	useFontawesome(app);
	useFloatingVue(app);
	useUnhead(app);

	// Setup PROD plugins
	if (!ENV.DEV) {
		useBugsnag(app);
	}

	app.use(VueSmoothScroll, { offset: -50 });
	app.use(autoAnimatePlugin);
	app.use(props.router);

	// Register global components
	app.component('IconFontAwesome', IconFontAwesome);

	return app;
};
