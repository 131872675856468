import { HttpServiceSymbol } from '@infrastructure/http/http.module';
import { type IHttpService } from '@infrastructure/http/services/http.service';
import {
	CreateOrganizationRequest,
	Organization,
	SlugValidationResponse,
	SubscriptionStatusResponse,
	UpdateOrganizationRequest,
} from '../types/organizations.types';

export class OrganizationsApiAdapter {
	private httpService: IHttpService;

	constructor(dependencies: { [HttpServiceSymbol]: IHttpService }) {
		this.httpService = dependencies[HttpServiceSymbol];
	}

	public async post(data: CreateOrganizationRequest): Promise<Organization> {
		const response = this.httpService.post('organizations', { json: data });

		return this.httpService.json<Organization>(response);
	}

	public async patch(
		organizationId: Organization['id'],
		data: UpdateOrganizationRequest,
	): Promise<Organization> {
		const response = this.httpService.patch(`organizations/${organizationId}`, { json: data });

		return this.httpService.json<Organization>(response);
	}

	public async validateSlug(data: { slug: string }): Promise<SlugValidationResponse> {
		const response = this.httpService.post('organizations/validate-slug', { json: data });

		return this.httpService.json<SlugValidationResponse>(response);
	}

	public async createInvitations(
		organizationId: Organization['id'],
		invitees: Array<string>,
	): Promise<void> {
		await this.httpService.post(`organizations/${organizationId}/invitations`, {
			json: { invitees },
		});
	}

	public async getSubscriptionStatus(
		organizationId: Organization['id'],
	): Promise<SubscriptionStatusResponse> {
		const response = this.httpService.get(`organizations/${organizationId}/subscription/status`);

		return this.httpService.json<SubscriptionStatusResponse>(response);
	}
}
