import { HttpServiceSymbol } from '@infrastructure/http/http.module';
import { type IHttpService } from '@infrastructure/http/services/http.service';
import { Integration, Integrations } from '@modules/integrations/types/integrations.types';
import { Organization } from '@modules/organizations/types/organizations.types';

export class IntegrationsApiAdapter {
	private httpService: IHttpService;

	constructor(dependencies: { [HttpServiceSymbol]: IHttpService }) {
		this.httpService = dependencies[HttpServiceSymbol];
	}

	public async get(orgId: Organization['id']): Promise<Integrations> {
		const response = this.httpService.get(`integrations?organizationId=${orgId}`);

		return this.httpService.json<Integrations>(response);
	}

	async updateIntegration(
		integrationId: Integration['id'],
		orgId: Organization['id'],
		payload: Integration,
	) {
		await this.httpService.patch(`integrations/${integrationId}?organizationId=${orgId}`, {
			json: payload,
		});
	}

	public async googleCalendarAuthorize(code: string, state: string): Promise<void> {
		await this.httpService.post('integrations/google/calendar/oauth2callback', {
			json: { code, state },
		});
	}

	public async disconnectGoogleCalendar(organizationId: Organization['id']) {
		await this.httpService.delete(`integrations/google/calendar?organizationId=${organizationId}`);
	}
}
