import {
	CheckoutEventNames,
	CheckoutOpenOptions,
	initializePaddle,
	Paddle,
	PaddleEventData,
} from '@paddle/paddle-js';
import { useEventBus } from '@vueuse/core';
import { ENV } from '@common/env';
import { OrganizationsServiceSymbol } from '@modules/organizations/organizations.module';
import { OrganizationsService } from '@modules/organizations/services/organizations.service';
import { useCommonStore } from '@common/store/common.store';
import { CheckoutCompletedEvent } from '@modules/billing/events/checkout-completed.event';

export class BillingService {
	paddleInstance: Paddle | undefined;
	organizationsService: OrganizationsService;

	constructor(dependencies: { [OrganizationsServiceSymbol]: OrganizationsService }) {
		this.organizationsService = dependencies[OrganizationsServiceSymbol];
		this.paddleInstance = undefined;
		this.initialize();
	}

	openCheckout(customerId?: string) {
		const commonStore = useCommonStore();

		let checkoutConfig: CheckoutOpenOptions = {
			items: [{ priceId: ENV.PADDLE_PRICE_ID, quantity: 1 }],
			settings: {
				theme: commonStore.darkMode ? 'dark' : 'light',
			},
		};

		if (customerId) {
			checkoutConfig.customer = { id: customerId };
		}

		this.paddleInstance && this.paddleInstance.Checkout.open(checkoutConfig);
	}

	// pricePreview() {
	// 	this.paddleInstance
	// 		.PricePreview({ items: [{ priceId: ENV.PADDLE_PRICE_ID, quantity: 1 }] })
	// 		.then((data) => console.log(data));
	// }

	async initialize() {
		const paddle = await initializePaddle();
		if (paddle) {
			paddle.Environment.set('sandbox');
			paddle.Setup({
				token: ENV.PADDLE_TOKEN,
				eventCallback: async (event: PaddleEventData) => {
					if (event.name === CheckoutEventNames.CHECKOUT_CUSTOMER_CREATED) {
						const organizationId = this.organizationsService.getCurrentOrganizationId();
						this.organizationsService.update(organizationId, {
							billingAccountId: event.data?.customer.id,
						});
					}

					if (event.name === CheckoutEventNames.CHECKOUT_COMPLETED) {
						useEventBus(CheckoutCompletedEvent).emit();
						this.paddleInstance && this.paddleInstance.Checkout.close();
					}
				},
			});
			this.paddleInstance = paddle;
		}
	}
}
