export default {
	// Global styles apply to _all_ inputs with matching section keys
	global: {
		fieldset: 'max-w-md border border-border rounded px-2 pb-1',
		help: 'text-xs text-text-weak',
		inner:
			'formkit-disabled:bg-neutral-weak formkit-disabled:cursor-not-allowed formkit-disabled:pointer-events-none',
		input:
			'appearance-none bg-transparent border-none border-0 focus:outline-none focus:ring-0 focus:shadow-none',
		label: 'block text-sm',
		legend: 'font-bold text-sm',
		loaderIcon: 'inline-flex items-center w-4 text-weak animate-spin',
		message: 'text-text-danger mb-1 text-xs',
		messages: 'list-none p-0 mt-1 mb-0',
		outer: 'mb-4 last:mb-0 formkit-disabled:opacity-50',
		prefixIcon:
			'w-10 flex self-stretch grow-0 shrink-0 rounded-tl rounded-bl border-r border-border bg-background bg-gradient-to-b from-transparent to-background-neutral-weak [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
		suffixIcon:
			'w-7 pr-3 flex self-stretch grow-0 shrink-0 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
	},

	// Family styles apply to all inputs that share a common family
	'family:box': {
		fieldset: '$reset pt-3 border-none',
		decorator:
			'block relative h-5 w-5 mr-2 rounded bg-background ring-1 ring-border peer-checked:ring-border-primary-focus text-transparent peer-checked:text-text-success cursor-pointer',
		decoratorIcon:
			'flex p-[3px] w-full h-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2',
		help: 'mb-2 mt-1.5',
		input: 'fixed left-[999em] w-0 h-0 overflow-hidden opacity-0 pointer-events-none peer',
		label: '$reset text-sm text-text-weak mt-1 select-none cursor-pointer',
		wrapper: 'flex items-center mb-1',
	},
	'family:button': {
		input:
			'$reset bg-background-primary hover:bg-background-primary-hover text-text-inverted dark:text-text font-extrabold tracking-wide inline-flex items-center justify-center gap-2 rounded-md px-5 text-sm transition-colors hover:cursor-pointer formkit-disabled:bg-background-neutral formkit-disabled:cursor-not-allowed py-3',
		wrapper: 'mb-1',
		prefixIcon: '$reset block w-4 -ml-2 mr-2 stretch',
		suffixIcon: '$reset block w-4 ml-2 stretch',
	},
	'family:dropdown': {
		dropdownWrapper: 'my-2 w-full shadow-shadow shadow-lg rounded [&::-webkit-scrollbar]:hidden',
		inner:
			'max-w-md relative flex ring-1 ring-border focus-within:ring-border-primary-focus focus-within:ring-2 rounded mb-1 formkit-disabled:focus-within:ring-border formkit-disabled:focus-within:ring-1 [&>span:first-child]:focus-within:text-te',
		input: 'w-full px-3 py-2',
		label: 'mb-2',
		listbox:
			'bg-background-neutral shadow-shadow shadow-lg rounded overflow-hidden divide-y divide-divider',
		listboxButton: 'flex w-12 self-stretch justify-center mx-auto',
		listitem:
			'py-2 relative hover:bg-background-neutral-hover data-[is-active="true"]:bg-background-neutral-hover data-[is-active="true"]:aria-selected:bg-background-neutral-hover aria-selected:bg-background-neutral',
		loaderIcon: 'ml-auto',
		loadMoreInner:
			'flex items-center justify-center text-sm p-2 text-center w-full text-te formkit-loading:text-text-weak cursor-pointer [&>span]:mr-3 [&>span]:ml-0',
		option: 'p-2.5',
		optionLoading: 'text-text-weak',
		placeholder: 'p-2.5 text-text-weak',
		selector: 'flex w-full justify-between items-center [&u]',
		selectedIcon: 'block absolute top-1/2 left-2 w-3 -translate-y-1/2',
		selectIcon: 'flex box-content w-4 px-2 self-stretch grow-0 shrink-0',
	},
	'family:text': {
		inner:
			'flex items-center ring-1 ring-border focus-within:ring-border-primary-focus focus-within:ring-2 [&>label:first-child]:focus-within:text-te rounded mb-1',
		input: 'w-full px-3 py-2 border-none border-0 text-base text-text placeholder-text-weak',
		label: 'mb-2',
	},
	'family:date': {
		inner:
			'flex items-center max-w-md ring-1 ring-border focus-within:ring-border-primary-focus focus-within:ring-2 [&>label:first-child]:focus-within:text-te rounded mb-1',
		input: 'w-full px-3 py-2 border-none border-0 text-text placeholder-text-weak',
		label: 'mb-2',
	},

	form: {
		form: '$reset mb-0 w-full',
	},

	// Specific styles apply only to a given input type
	color: {
		inner:
			'flex max-w-[5.5em] w-full formkit-prefix-icon:max-w-[7.5em] formkit-suffix-icon:formkit-prefix-icon:max-w-[10em]',
		input:
			'$reset appearance-none w-full cursor-pointer border-none border-0 rounded p-0 m-0 bg-transparent [&::-webkit-color-swatch-wrapper]:p-0 [&::-webkit-color-swatch]:border-none border-0',
		suffixIcon: 'min-w-[2.5em] pr-0 pl-0 m-auto',
	},
	file: {
		fileItem: 'flex items-center text-text mb-1 last:mb-0',
		fileItemIcon: 'w-4 mr-2 shrink-0',
		fileList: 'shrink grow peer px-3 py-2 formkit-multiple:data-[has-multiple="true"]:mb-6',
		fileName: 'break-all grow text-ellipsis',
		fileRemove:
			'relative z-[2] ml-auto text-[0px] hover:text-text-danger pl-2 peer-data-[has-multiple=true]:text-sm peer-data-[has-multiple=true]:text-te peer-data-[has-multiple=true]:ml-3 peer-data-[has-multiple=true]:mb-2 formkit-multiple:bottom-[0.15em] formkit-multiple:pl-0 formkit-multiple:ml-0 formkit-multiple:left-[1em] formkit-multiple:formkit-prefix-icon:left-[3.75em]',
		fileRemoveIcon: 'block text-base w-3 relative z-[2]',
		inner: 'relative max-w-md cursor-pointer formkit-multiple:[&>button]:absolute',
		input: 'cursor-pointer text-transparent absolute top-0 right-0 left-0 bottom-0 opacity-0 z-[2]',
		noFiles: 'flex w-full items-center px-3 py-2 text-text-weak',
		noFilesIcon: 'w-4 mr-2',
	},
	radio: {
		decorator: 'rounded-full',
		decoratorIcon: 'w-5 p-[5px]',
	},
	range: {
		inner: '$reset flex items-center max-w-md',
		input: '$reset w-full mb-1 h-2 p-0 rounded-full',
		prefixIcon:
			'$reset w-4 mr-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
		suffixIcon:
			'$reset w-4 ml-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
	},
	select: {
		inner:
			'flex relative items-center rounded mb-1 ring-1 ring-border focus-within:ring-border-primary-focus focus-within:ring-2 [&>span:first-child]:focus-within:text-te',
		input:
			'w-full pl-3 pr-8 py-2 border-none border-0 text-base text-text placeholder-text-weak formkit-multiple:p-0 data-[placeholder="true"]:text-text-weak formkit-multiple:data-[placeholder="true"]:text-inherit',
		selectIcon: 'flex p-[3px] shrink-0 w-5 mr-2 -ml-[1.5em] h-full pointer-events-none',
		option: 'formkit-multiple:p-3 formkit-multiple:text-sm text-text',
	},
	textarea: {
		inner:
			'flex rounded mb-1 ring-1 ring-border focus-within:ring-border-primary-focus [&>label:first-child]:focus-within:text-text',
		input:
			'block w-full h-32 p-2 border-none border-0 text-base text-text placeholder-text-weak focus:shadow-outline',
	},
	submit: {
		outer: '$reset mt-4 mb-0',
	},

	// PRO input styles
	autocomplete: {
		closeIcon: 'block grow-0 shrink-0 w-3 h-3 mr-3.5 rounded hover:bg-background-neutral-hover',
		inner: '[&>div>[data-value]]:absolute [&>div>[data-value]]:mb-0',
		option: 'cursor-pointer text-ellipsis',
		selections: 'divide-y divide-divider mt-4',
		selectionWrapper: 'flex items-center py-2 first:pt-0 last:pb-0',
		selection: 'flex-1',
	},
	calendar: {
		header: 'flex justify-center items-center',
	},
	datepicker: {
		inner: 'relative',
		panelWrapper:
			'absolute top-[calc(100%_+_0.5em)] shadow-shadow shadow-[0_0_1.25em_rgba(0,0,0,.25)] rounded-md p-5 bg-background z-10',
		panelHeader:
			'grid grid-cols-[2.5em_1fr_2.5em] justify-center items-center border-b-2 mb-4 pb-4',
		input: 'selection:bg-selection',
		monthsHeader: 'flex items-center justify-center col-start-2 col-end-2',
		timeHeader: 'flex items-center justify-center col-start-2 col-end-2',
		overlayPlaceholder: 'text-text-weak',
		months: 'flex flex-wrap',
		month: `
      flex items-center justify-center
      w-[calc(33%_-_1em)] m-2 p-2 rounded-md
      bg-neutral-weak
      aria-selected:bg-background-primary aria-selected:text-text-inverted
      focus:outline focus:outline-2 focus:outline-border-primary-focus focus:outline-offset-2 focus:bg-background focus:text-text
      data-[is-extra=true]:opacity-25
      formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none
    `,
		yearsHeader: 'flex items-center justify-center col-start-2 col-end-2',
		years: 'flex flex-wrap max-w-[35em]',
		year: `
      flex items-center justify-center
      w-[calc(20%_-_1em)] m-2 p-2 rounded-md
      bg-neutral-weak
      aria-selected:bg-background-primary aria-selected:text-text-inverted
      focus:outline focus:outline-2 focus:outline-border-primary-focus focus:outline-offset-2 focus:bg-background focus:text-text
      data-[is-extra=true]:opacity-25
      formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none
    `,
		weekDays: 'flex justify-evenly',
		weekDay: 'flex w-[2.25em] h-[1em] m-1 items-center justify-center rounded-md font-medium',
		week: 'flex justify-evenly formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none',
		dayCell: 'group',
		day: `
      flex items-center justify-center
      w-[2.25em] h-[2.25em] m-1 p-2 rounded-md
      bg-background-neutral-weak cursor-pointer hover:bg-background-neutral-hover 
      group-aria-selected:bg-background-primary group-aria-selected:text-text-inverted
      focus:outline focus:outline-2 focus:outline-border-primary-focus focus:outline-offset-2 focus:bg-background focus:text-text
      group-data-[is-extra=true]:opacity-25
      formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none
    `,
		timeInput:
			'w-full border-2 border-border rounded-md p-2 my-[2em] focus-visible:outline-border-primary-focus',
		daysHeader: 'flex items-center justify-center',
		prev: 'mr-auto px-3 py-1 hover:bg-background-hover hover:rounded-lg col-start-1 col-end-1',
		prevLabel: 'hidden',
		prevIcon: 'flex w-3 select-none [&>svg]:w-full',
		dayButton:
			'appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-primary-focus',
		monthButton:
			'appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-primary-focus',
		yearButton:
			'appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-primary-focus',
		next: 'ml-auto px-3 py-1 hover:bg-background-hover hover:rounded col-start-3 col-end-3',
		nextLabel: 'hidden',
		nextIcon: 'flex w-3 select-none [&>svg]:w-full',
		openButton: `
      appearance-none border-0 bg-transparent flex p-0 self-stretch cursor-pointer
      focus-visible:outline-none focus-visible:text-text-inverted focus-visible:bg-background-primary
    `,
		calendarIcon:
			'flex w-8 grow-0 shrink-0 self-stretch select-none [&>svg]:w-full [&>svg]:m-auto [&>svg]:max-h-[1em] [&>svg]:max-w-[1em]',
	},
	mask: {
		inner:
			'flex items-center max-w-md ring-1 ring-border focus-within:ring-border-primary-focus focus-within:ring-2 [&>label:first-child]:focus-within:text-te rounded mb-1',
		input: 'w-full px-3 py-2 border-none border-0 text-base text-text placeholder-text-weak',
	},
	rating: {
		inner: 'relative flex items-center w-[8em] formkit-disabled:bg-transparent',
		itemsWrapper: 'w-full',
		onItems: 'text-yellow-400',
		onItemWrapper: '[&>*]:w-full [&>svg]:h-auto [&>svg]:max-w-none [&>svg]:max-h-none',
		offItems: 'text-text-weak',
		offItemWrapper: '[&>*]:w-full [&>svg]:h-auto [&>svg]:max-w-none [&>svg]:max-h-none',
	},
	repeater: {
		content: 'grow p-3 flex flex-col align-center',
		controlLabel: 'absolute opacity-0 pointer-events-none',
		controls: 'flex flex-col items-center justify-center bg-background-neutral-weak p-3',
		downControl: 'hover:text-te disabled:hover:text-inherit disabled:opacity-25',
		fieldset: 'py-4 px-5',
		help: 'mb-2 mt-1.5',
		item: 'flex w-full mb-1 rounded border border-border-weak',
		moveDownIcon: 'block w-3 my-1',
		moveUpIcon: 'block w-3 my-1',
		removeControl: 'hover:text-te disabled:hover:text-inherit disabled:opacity-25',
		removeIcon: 'block w-5 my-1',
		upControl: 'hover:text-te disabled:hover:text-inherit disabled:opacity-25',
	},
	taglist: {
		input: 'px-1 py-1 w-[0%] grow',
		removeSelection: 'w-2.5 mx-1 self-center text-text leading-none',
		tag: 'flex items-center my-1 p-1 bg-neutral-weak text-xs rounded-full',
		tagWrapper:
			'mr-1 focus:outline-none focus:text-text-inverted [&>div]:focus:bg-background-primary [&>div>button]:focus:text-text-inverted',
		tagLabel: 'pl-2 pr-1',
		tags: 'flex items-center flex-wrap w-full py-1.5 px-2',
	},
	toggle: {
		altLabel: 'block w-full font-bold text-sm',
		inner: '$reset inline-block mr-2',
		input: 'peer fixed left-[999em] opacity-0 pointer-events-none',
		innerLabel:
			'text-[10px] font-bold absolute left-full top-1/2 -translate-x-full -translate-y-1/2 px-1',
		label: 'mb-0 cursor-pointer',
		thumb: 'relative left-0 aspect-square rounded-full transition-all w-5 bg-white',
		track:
			'p-0.5 min-w-[3em] relative rounded-full transition-all bg-background-neutral-weak cursor-pointer peer-checked:bg-background-primary peer-checked:[&>div:last-child]:left-full peer-checked:[&>div:last-child]:-translate-x-full peer-checked:[&>div:first-child:not(:last-child)]:left-0 peer-checked:[&>div:first-child:not(:last-child)]:translate-x-0',
		valueLabel: 'font-bold text-sm',
		wrapper: 'flex flex-wrap items-center',
	},
	transferlist: {
		outer: `
      [&_.dnd-placeholder]:bg-background-primary [&_.dnd-placeholder]:text-text-inverted
      [&_.dnd-placeholder_svg]:text-text-inverted
      [&_.dnd-children-hidden]:w-full [&_.dnd-children-hidden]:p-0 [&_.dnd-children-hidden]:flex [&_.dnd-children-hidden]:flex-col [&_.dnd-children-hidden]:border-none border-0
      [&_.dnd-children-hidden_span]:hidden
      [&_.dnd-children-hidden_.formkit-transferlist-option]:hidden
      [&_.dnd-multiple-selections_span]:inline-block
      [&_.dnd-multiple-selections_.formkit-transferlist-option]:inline-block
    `,
		fieldset: '$reset max-w-2xl',
		wrapper: 'flex max-h-[350px] flex-col sm:flex-row justify-between w-full max-w-none',
		help: 'pb-2',
		transferlist:
			'sm:w-3/5 shadow-md flex flex-col min-h-[350px] max-h-[350px] border rounded overflow-hidden select-none bg-background-neutral-weak',
		transferlistHeader: 'flex bg-background-neutral-weak justify-between items-center border-b p-3',
		transferlistHeaderItemCount: 'ml-auto text-sm',
		transferlistListItems:
			'list-none bg-background-neutral-weak h-full sm:max-w-xs overflow-x-hidden overflow-y-auto',
		transferlistListItem:
			'pl-8 relative aria-selected:bg-background-primary aria-selected:data-[is-active=true]:bg-background-primary aria-selected:text-text-inverted aria-selected:data-[is-active=true]:text-text-inverted first:-mt-px first:border-t py-2 px-3 flex relative border-b bg-background data-[is-active=true]:text-te data-[is-active=true]:bg-background-neutral-weak cursor-pointer group-data-[is-max=true]:cursor-not-allowed items-center',
		transferlistOption: 'text-sm',
		transferControls:
			'flex sm:flex-col justify-center mx-auto my-2 sm:mx-2 sm:my-auto border rounded',
		transferlistButton:
			'text-sm disabled:cursor-not-allowed disabled:bg-neutral-weak disabled:opacity-50 first:rounded-l last:rounded-r sm:first:rounded-t sm:last:rounded-b appearance-none p-2 m-0 cursor-pointer h-10 border-none border-0 rounded-none bg-background-neutral-weak hover:outline disabled:hover:outline-none hover:outline-1 hover:outline-black hover:text-te disabled:hover:text-current hover:z-10',
		sourceEmptyMessage:
			'appearance-none border-none border-0 w-full p-0 m-0 text-center text-text-weak italic',
		sourceListItems: 'group-data-[is-max=true]:opacity-50',
		targetEmptyMessage:
			'appearance-none border-none border-0 w-full p-0 m-0 text-center text-text-weak italic',
		emptyMessageInner: 'flex items-center justify-center p-2 text-sm',
		transferlistControls: 'bg-background px-3 py-2 border-b',
		transferlistSearch: 'flex border rounded items-center',
		transferlistSearchInput: 'border-none border-0 p-1 w-full bg-transparent outline-none text-sm',
		controlLabel: 'hidden',
		selectedIcon: 'w-3 absolute left-3 select-none',
		fastForwardIcon:
			'w-10 flex select-none [&>svg]:m-auto [&>svg]:w-full [&>svg]:max-w-[1rem] [&>svg]:max-h-[1rem] rotate-90 sm:rotate-0',
		moveRightIcon:
			'w-10 flex select-none [&>svg]:m-auto [&>svg]:w-full [&>svg]:max-w-[1rem] [&>svg]:max-h-[1rem] rotate-90 sm:rotate-0',
		moveLeftIcon:
			'w-10 flex select-none [&>svg]:m-auto [&>svg]:w-full [&>svg]:max-w-[1rem] [&>svg]:max-h-[1rem] rotate-90 sm:rotate-0',
		rewindIcon:
			'w-10 flex select-none [&>svg]:m-auto [&>svg]:w-full [&>svg]:max-w-[1rem] [&>svg]:max-h-[1rem] rotate-90 sm:rotate-0',
	},
};
