import { createRouter, type RouterOptions } from 'vue-router';
import { AuthService } from '@modules/auth/services/auth.service';

const getRouter = (options: Partial<RouterOptions>) => {
	const router = createRouter({
		...options,
		routes: [
			{
				path: '/auth',
				component: () => import('./application/AuthContext.vue'),
				children: [
					{
						path: 'login',
						name: 'login',
						component: () => import('./application/modules/auth/pages/LoginPage.vue'),
						beforeEnter: (to) => {
							if (AuthService.isLoggedIn) {
								return { name: 'dashboard', query: to.query };
							}
						},
					},
					{
						path: 'signup',
						name: 'signup',
						component: () => import('./application/modules/auth/pages/SignupPage.vue'),
						beforeEnter: (to) => {
							if (AuthService.isLoggedIn) {
								return { name: 'dashboard', query: to.query };
							}
						},
					},
					{
						path: 'logout',
						name: 'logout',
						component: () => import('./application/modules/auth/pages/LogoutPage.vue'),
					},
				],
			},
			{
				path: '/',
				component: () => import('./application/AppContext.vue'),
				beforeEnter: (to, from) => {
					if (!AuthService.isLoggedIn) {
						return { name: 'login', query: { message: 'loginRequired', target: to.path } };
					}
				},
				children: [
					{
						path: 'org/:orgSlug',
						name: 'organization',
						children: [
							{
								path: '',
								name: 'org-dashboard',
								redirect: { name: 'org-personal-board' },
							},
							{
								path: 'people',
								name: 'org-people',
								component: () => import('./application/modules/actors/pages/PageActorsList.vue'),
							},
							{
								path: 'people/:actorId/boards/personal',
								name: 'org-actor-personal-board',
								component: () =>
									import('./application/modules/boards/pages/PageOrgPersonalBoardOfActor.vue'),
							},
							{
								path: 'personal-board',
								name: 'org-personal-board',
								component: () =>
									import('./application/modules/boards/pages/PageOrgPersonalBoard.vue'),
							},
							{
								path: 'boards',
								name: 'org-boards',
								component: () => import('./application/modules/boards/pages/PageBoardsList.vue'),
							},
							{
								path: 'boards/:boardId',
								name: 'org-board',
								component: () => import('./application/modules/boards/pages/PageSharedBoard.vue'),
							},
							{
								path: 'new-board',
								name: 'org-new-board',
								component: () => import('./application/modules/boards/pages/PageNewBoard.vue'),
							},
							// TODO: APP-37
							// {
							// 	path: 'get-started/plan',
							// 	name: 'org-onboarding-choose-plan',
							// 	component: () => import('@modules/organizations/pages/PageOnboardingPlan.vue'),
							// },
							{
								path: 'get-started/invite',
								name: 'org-onboarding-invite',
								component: () => import('@modules/organizations/pages/PageOnboardingInvite.vue'),
							},
							{
								path: 'profile',
								name: 'org-profile',
								component: () => import('@modules/organizations/pages/PageSubscriptionPlans.vue'),
							},
							{
								path: 'integrations',
								name: 'org-integrations',
								component: () => import('@modules/integrations/pages/PageIntegrations.vue'),
							},
							// TODO: APP-37
							// {
							// 	path: 'subscription',
							// 	name: 'org-subscription',
							// 	component: () => import('@modules/organizations/pages/PageSubscriptionPlans.vue'),
							// },
							// {
							// 	path: 'subscription/plans',
							// 	name: 'org-subscription-plans',
							// 	component: () => import('@modules/organizations/pages/PageSubscriptionPlans.vue'),
							// },
						],
					},
					{
						path: 'new-org',
						name: 'new-org',
						component: () =>
							import('./application/modules/organizations/pages/PageNewOrganization.vue'),
					},
					{
						path: 'new-org/slug',
						name: 'new-org-slug',
						component: () =>
							import('./application/modules/organizations/pages/PageNewOrganizationSlug.vue'),
					},
					{
						path: '',
						name: 'dashboard',
						component: () => import('./application/modules/boards/pages/PagePrivateBoard.vue'),
					},
					{
						path: 'preferences',
						name: 'preferences',
						component: () => import('./application/modules/users/pages/PagePreferences.vue'),
					},
					{
						path: 'account',
						name: 'account',
						component: () => import('./application/modules/users/pages/PageAccount.vue'),
					},
					{
						path: 'get-started',
						name: 'get-started',
						component: () => import('./application/modules/users/pages/PageGetStarted.vue'),
					},
					{
						path: 'boards',
						name: 'boards',
						component: () => import('./application/modules/boards/pages/PageBoardsList.vue'),
					},
					{
						path: 'boards/:boardId',
						name: 'board',
						component: () => import('./application/modules/boards/pages/PageSharedBoard.vue'),
					},
					{
						path: 'integrations',
						name: 'integrations',
						component: () =>
							import('./application/modules/integrations/pages/PageIntegrations.vue'),
					},
				],
			},
		],
	} as RouterOptions);

	return router;
};

export { getRouter };
