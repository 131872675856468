import { asClass } from 'awilix';
import { useDependenciesContainer } from '@common/dependencies/useDependenciesContainer';
import { BillingService } from '@modules/billing/services/billing.service';

export const BillingServiceSymbol: unique symbol = Symbol('Billing Service');
export const billingModule = {
	[BillingServiceSymbol]: asClass(BillingService).singleton(),
};

export function useBillingModule() {
	const container = useDependenciesContainer();
	return {
		billingService: container.resolve(BillingServiceSymbol) as BillingService,
	};
}
